.banner {
  width: 100%;
  height: 3.24rem;
  background: url(../../assets/components/banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0.56rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}

.banner_top {
  cursor: pointer;
  width: 12rem;
  padding-bottom: 0.6rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.banner_top_left {
  width: 4.5rem;
  height: 0.82rem;
  background: url(../../assets/main/banner1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.banner_top_right {
  margin-left: 0.1rem;
  width: 0.68rem;
  height: 0.24rem;
  background: url(../../assets/components/rightpic.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.banner_top_right2 {
  margin-left: 0.1rem;
  width:0.8rem;
  height: 0.24rem;
  background: url(../../assets/components/rightpic2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.banner_bottom {
  width: 12rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner_bottom_search {
  width: 70%;
}

.banner_bottom_button {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner_bottom_button_shop {
    margin-left: .3rem;
  background: #3fd5d3 !important;
  color: #fff !important;
  border-color: #3fd5d3 !important;
}
