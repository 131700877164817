.main_content {
  background: #f6f6f8;
}

.my_place {
  background: #fff;
}

.my_place_top {
  width: 12rem;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
}

.my_place_top_left {
  position: relative;
  top: -0.69rem;
  padding-right: 0.24rem;
}

.my_place_top_right {
  width: 90%;
  padding: 0.26rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.01rem solid rgba(42, 44, 44, 0.15);
}

.my_place_top_right_info_title {
  font-size: 0.24rem;
  font-weight: 700;
}

.my_place_top_right_info_sign {
  padding-top: 0.12rem;
  color: rgba(42, 44, 44, 0.65);
  font-size: 0.14rem;
}

.my_place_top_right_info_sign_email {
  padding-left: 0.4rem;
}

.my_place_top_right_info {
  width: 90%;
}

.my_place_top_right_icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.my_place_tabs {
  width: 8.8rem;
  margin: 0 auto;
  padding-top: 0.66rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my_place_tabs_item {
  cursor: pointer;
  flex: 1 1 0;
  font-size: 0.16rem;
  color: rgba(42, 44, 44, 0.65);
}

.my_place_tabs_item_active {
  color: #2a2c2c;
}

.my_place_tabs_item_active::after {
  content: "";
  width: 0.32rem;
  height: 0.04rem;
  display: block;
  margin: 0 8%;
  background: #3fd5d3;
}

.my_place_content {
  width: 12rem;
  margin: 0 auto;
  margin-top: 0.24rem;
  margin-bottom: 0.72rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.my_place_content_left {
  width: 30%;
  background: #fff;
  padding: 0.3rem 0.3rem;
  border-radius: 0.08rem;
}

.my_place_content_left_title {
  font-size: 0.16rem;
  color: #2a2c2c;
  padding-bottom: 0.16rem;
  border-bottom: 0.01rem solid rgba(42, 44, 44, 0.15);
}

.my_place_content_left_content {
  padding-top: 0.24rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.my_place_content_left_content_item {
  cursor: pointer;
  width: 33%;
  padding: 0.1rem 0;
  text-align: center;
  color: #2a2c2c;
}

.my_place_content_left_content_item_active {
  color: #fff;
  background: #18b2b0;
  border-radius: 0.04rem;
}

.my_place_content_right {
  width: 67%;
  background: #ffffff;
  border-radius: 0.08rem;
}

.shop_place_content_right {
  width: 100%;
  background: #ffffff;
  border-radius: 0.08rem;
}

.shop_content_list_item_text_item {
  text-align: right;
  padding-right: 0.6rem;
  padding-bottom: 0.2rem;
}

.my_place_content_right_item {
  cursor: pointer;
}

.my_place_content_right_item_title {
  background: #18b2b0;
  color: #fff;
  padding: 0.13rem 0.59rem;
}

.my_place_content_right_item_maintitle {
  font-size: 0.16rem;
}

.my_place_content_right_item_content {
  padding: 0.46rem 0.59rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.my_place_content_right_item_content_left {
  width: 40%;
}

.my_place_content_right_item_content_right {
  width: 70%;
}

.my_place_content_right_item_content_right_item {
  padding-bottom: 0.32rem;
  color: rgba(42, 44, 44, 0.85);
}

.my_place_content_other {
  width: 12rem;
  margin: 0 auto;
  margin-top: 0.24rem;
  margin-bottom: 0.72rem;
}

.my_place_content_title {
  color: #fff;
  width: 12rem;
  font-size: 0.14rem;
  padding: 0.15rem 0.3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #18b2b0;
  border-radius: 8px 8px 0px 0px;
}

.my_place_content_list {
  padding: 0.42rem 0.4rem;
  padding-top: 0;
  background: #ffffff;
}

.my_place_content_list_item {
  cursor: pointer;
  padding-top: 0.26rem;
  padding-bottom: 0.16rem;
  border-bottom: 1px solid rgba(42, 44, 44, 0.15);
}

.my_place_content_list_item_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my_place_content_list_item_title_item {
  font-size: 0.2rem;
  color: #2a2c2c;
}

.my_place_content_list_item_edit {
  font-size: 0.2rem;
  color: #2a2c2c;
}

.my_place_content_title_item:nth-child(1) {
  padding-right: 0.52rem;
}

.my_place_content_list_item_text {
  padding-top: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my_place_content_list_item_text_item {
  color: rgba(42, 44, 44, 0.65);
}

.my_place_content_list_item_text_item:nth-child(1) {
  width: 75%;
}

.my_place_content_list_item_text_item:nth-child(2) {
  width: 25%;
  text-align: right;
}

.my_place_content_list_item_text_item_content {
  padding-right: 0.52rem;
}

.people_setting {
  padding: 0.6rem 0.4rem;
  width: 12rem;
  margin: 0 auto;
  margin-top: 0.24rem;
  margin-bottom: 0.52rem;
  background: #ffffff;
}

.people_setting_title {
  font-size: 0.2rem;
  color: #2a2c2c;
}

.people_setting_title::after {
  content: "";
  display: block;
  margin-top: 0.1rem;
  width: 0.67rem;
  height: 0.04rem;
  background: #3fd5d3;
}

.people_setting_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.46rem;
  padding-bottom: 0.26rem;
  border-bottom: 1px solid rgba(42, 44, 44, 0.15);
}

.people_setting_item_left {
  font-size: 0.16rem;
}

.people_setting_item_right {
  cursor: pointer;
  font-size: 0.16rem;
  color: #18b2b0;
}

.people_message {
  width: 12rem;
  height: 7.44rem;
  margin: 0 auto;
  margin-top: 0.24rem;
  margin-bottom: 0.52rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.people_message::before {
  content: "";
  display: block;
  width: 0.06rem;
  height: 7.44rem;
  background: linear-gradient(
    180deg,
    rgba(63, 213, 211, 0) -100%,
    #3fd5d3 11.46%,
    rgba(63, 213, 211, 0) 100%
  );
  left: 0.14rem;
  position: relative;
  border-radius: 0.1rem 0 0 0;
}

.people_message_left {
  overflow: auto;
  width: 14%;
  max-height: 7.44rem;
  height: 7.44rem;
  background: #fff;
  padding: 0.18rem 0;
  border-radius: 0 0.08rem 0.08rem 0.08rem;
}

.people_message_left_top {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
  padding-left: 0.18rem;
  padding-bottom: 0.2rem;
  font-size: 0.14rem;
}

.people_message_left_item {
  padding: 0.18rem 0.18rem;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
}

.people_message_left_item_active {
  color: #ffffff;
  padding: 0.18rem 0.18rem;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
  background: #3fd5d3;
}

.people_message_right {
  position: relative;
  width: 84%;
  max-height: 7.5rem;
  height: 7.5rem;
  padding: 0.1rem 0.4rem;
  background: #fff;
}

.people_message_left_item_text {
  padding-left: 0.08rem;
  font-size: 0.14rem;
}

.people_message_right_message {
  width: 100%;
  height: 5.5rem;
  max-height: 5.5rem;
  overflow: auto;
  padding-right: 0.2rem;
  padding-bottom: 0.2rem;
}

.people_message_right_message_left {
  text-align: left;
}

.people_message_right_message_left_name {
  font-size: 0.14rem;
  color: rgba(42, 44, 44, 0.65);
}

.message_time {
  text-align: center;
  padding: 0.1rem;
}

.people_message_right_message_left_text {
  font-size: 0.14rem;
  /* width: 50%; */
  padding: 0.1rem 0.16rem;
  color: #fff;
  background: #18b2b0;
  display: inline-flex;
  text-align: right;
}

.people_message_right_message_right {
  text-align: right;
}

.people_message_right_message_right_name {
  font-size: 0.14rem;
  color: rgba(42, 44, 44, 0.65);
}

.people_message_right_message_right_text {
  font-size: 0.14rem;
  /* width: 50%; */
  /* margin-left: 50%; */
  padding: 0.1rem 0.16rem;
  color: rgba(42, 44, 44, 0.65);
  background: rgba(42, 44, 44, 0.06);
  display: inline-flex;
  text-align: left;
}

.people_message_right_send_message {
  /* position: absolute; */
  bottom: 0.1rem;
  width: 100%;
  padding-top: 0.1rem ;
}

.people_message_right_send_message_btn {
  padding-top: 0.05rem;
}

.my_place_content_right_pagion {
  text-align: center;
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
}

.sendMesage_editor {
  overflow: hidden;
  height: 1.5rem !important;
  width: 100%;
  border: 1px solid rgba(42, 44, 44, 0.15);
}

@media (max-width: 1000px),
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .person_phone_content {
  }
  .phone_person_top {
    padding-bottom: 100px;
    min-height: 40vh;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    filter: blur(5px) !important;
  }

  .phone_person_content {
    position: absolute;
    width: 100%;
    top: 0;
  }

  .phone_person_top_first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .phone_person_top_first_item {
    padding: 5px 10px;
    background: #8f8e8e;
    border-radius: 20px;
  }

  .phone_person_top_second {
    text-align: center;
    padding-top: 60px;
  }

  .phone_person_top_edit {
    padding: 5px 10px;
    background: #8f8e8e;
    border-radius: 20px;
    font-size: 15px;
    color: #ffffff;
  }

  .phone_person_top_second_name {
    font-size: 20px;
    font-weight: 700;
    padding: 10px;
    color: #ffffff;
  }

  .phone_person_top_second_mail {
    color: #ffffff;
  }

  .phone_person_content_tabs {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;
  }

  .phone_person_content_tab {
    padding: 5px 20px;
    background: #f6f6f6;
    border-radius: 20px;
    color: #000;
  }

  .phone_person_content_tab_active {
    padding: 5px 20px;
    background: #3fbf9e;
    border-radius: 20px;
    color: #000;
  }

  .phone_person_content_tabs_content {
    padding: 0 10px;
  }

  .phone_person_content_tabs_content_item {
    padding: 10px 0px;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .phone_person_content_tabs_content_item_left {
    font-weight: 700;
    font-size: 18px;
  }

  .phone_popup_edit_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .phone_popup_edit_top {
    padding: 10px;
  }

  .phone_popup_edit_top_left {
    width: 45%;
  }

  .phone_popup_edit_top_right {
    width: 55%;
  }

  .phone_popup_edit_content_avatar {
    padding-top: 20px;
    text-align: center;
  }
  .phone_popup_edit_content_right {
    font-size: 20px;
    color: #3fbf9e;
  }

  .phone_popup_edit_content_form {
    padding: 10px;
  }

  .loginOut {
    width: 100px;
    border-radius: 20px;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    padding: 5px 10px;
    background: #3fbf9e;
  }
}
