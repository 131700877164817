.page_content {
  height: 100vh;
  background-image: url("../../../assets/newpage/main-index/mainbg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.page_content_left_top {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.page_content_left_top_line {
  height: 0.25rem;
  width: 0.05rem;
  background: #6acccd;
  margin-right: 0.1rem;
}

.page_content_left_top_icon {
  width: 3.82rem;
  height: 0.69rem;
  background: url(../../../assets/newpage/main-index/main_logo.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.page_content_left_middle {
  padding-bottom: 0.3rem;
  color: #fff;
  font-size: 0.16rem;
  text-align: center;
}

.page_content_right {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_content_right_first {
  padding-top: 0.52rem;
  background: #03cac0;
  height: 5.38rem;
  width: 3.02rem;
}

.page_content_right_first_title {
  font-size: 0.24rem;
  color: #fff;
  font-weight: bold;
}

.page_content_right_first_line {
  margin: 0.1rem auto;
  width: 0.5rem;
  height: 0.01rem;
  background: #fff;
}

.page_content_right_first_column {
  padding: 0 0.53rem;
  color: #fff;
  font-size: 0.14rem;
}

.page_content_right_first_button {
  cursor: pointer;
  margin: 1.2rem auto;
  width: 1.44rem;
  height: 0.28rem;
  background: #fff;
  border-radius: 0.51rem;
  line-height: 0.28rem;
  text-align: center;
  font-size: 0.12rem;
  color: #03cac0;
}

.page_content_right_second_top {
  display: flex;
  align-items: center;
}

.page_content_right_second_top_first {
  padding-top: 0.52rem;
  background: #faad14;
  height: 3.02rem;
  width: 3.16rem;
}

.page_content_right_second_top_second {
  padding-top: 0.52rem;
  height: 3.02rem;
  width: 2.01rem;
  background: url(../../../assets/newpage/main-index/bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.page_content_right_second_top_second_button {
  cursor: pointer;
  width: 1.44rem;
  height: 0.28rem;
  background: #fff;
  border-radius: 0.51rem;
  line-height: 0.28rem;
  text-align: center;
  color: #417bf0;
  margin: 0 auto;
  font-size: 0.12rem;
}
 

.page_content_right_second_bottom {
  cursor: pointer;
  display: flex;
  align-content: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
  height: 2.36rem;
  width: 5.17rem;
  padding-right: 0.4rem;
  padding-top: 0.4rem;
  background: #417bf0;
}

.page_content_right_second_bottom_title {
  width: 100%;
  text-align: right;
  font-size: 0.24rem;
  color: #fff;
  font-weight: bold;
}

.page_content_right_second_bottom_line {
  margin: 0.1rem 0;
  width: 0.5rem;
  height: 0.01rem;
  background: #fff;
}

.page_content_right_second_bottom_column {
  width: 95%;

  text-align: right;
   
  color: #fff;
  font-size: 0.14rem;
}

.page_content_right_second_bottom_button {
  cursor: pointer;
  width: 1.44rem;
  height: 0.28rem;
  background: #fff;
  border-radius: 0.51rem;
  line-height: 0.28rem;
  text-align: center;
  color: #417bf0;
  margin-top: 0.2rem;
  font-size: 0.12rem;
}

.page_content_right_first_column_button {
  cursor: pointer;
  width: 1.44rem;
  height: 0.28rem;
  background: #fff;
  margin-left: 45%;
  border-radius: 0.51rem;
  line-height: 0.28rem;
  text-align: center;
  color: #faad14;
  margin-top: 0.2rem;
  font-size: 0.12rem;
}

.main_content {
  display: block;
}

.phone_page {
  display: none;
}

.phone_content {
  display: none;
}

@media (max-width: 1000px),
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .main_content {
    display: none;
  }

  .phone_content {
    display: block;
  }

  .phone_page {
    display: block;
    height: 100vh;
    overflow: auto;
    width: 100%;
    background-image: url("../../../assets/newpage/main-index/mainbg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .phone_page_top {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 1rem;
  }

  .phone_page_title {
    text-align: center;
    font-size: 1rem;
    padding-top: 1rem;
    color: #f3f3f3;
    font-weight: 700;
    letter-spacing: 0.4rem;
  }
  .phone_page_subtitle {
    text-align: center;
    font-size: 0.5rem;
    padding-top: 0.2rem;
    color: #f3f3f3;
    letter-spacing: 0.2rem;
  }

  .phone_page_top_search {
    padding-top: 0.4rem;
  }

  .phone_page_middle {
    width: 90%;
    margin: 0 auto;
  }

  .phone_page_middle_first {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .phone_page_middle_first_left {
    width: 50%;
    background: #03cac0;
    padding: 1rem;
    padding-top: 0.2rem;
    height: 10rem;
    text-align: center;
  }

  .phone_page_middle_first_right {
    text-align: center;
    width: 50%;
    height: 10rem;
  }

  .phone_page_middle_first_left_title {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
  }

  .phone_page_middle_first_left_subtitle {
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    padding: 5px 0;
  }

  .phone_page_middle_first_left_subtitle:nth-child(2)::after {
    content: "";
    display: block;
    width: 40%;
    margin: 0 auto;
    height: 0.05rem;
    background: #fff;
  }

  .phone_page_middle_first_left_column {
    font-size: 0.4rem;
    padding-bottom: 0.1rem;
    line-height: 20px;
    color: #fff;
  }

  .phone_page_content_right_first_button {
    margin: 0 auto;
    width: 90%;
    background: #fff;
    border-radius: 0.51rem;
    text-align: center;
    color: #03cac0;
  }
  .phone_page_middle_first_right_item {
    cursor: pointer;
    background: #faad14;
    color: #fff;
    height: 100%;
    width: 100%;
    padding: 0.2rem 0.4rem;
  }
  .phone_page_middle_first_right_bottom {
    background: url(../../../assets/newpage/main-index/bg.png);
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    width: 100%;
    padding: 10px 10px;
  }
  
  .phone_page_middle_first_right_bottom_title {
    text-align: right;
  }



  .phone_page_middle_first_right_bottom_title::after {
    content: "";
    display: block;
    width: 20%;
    margin-left: 80%;
    height: 0.05rem;
    background: #fff;
  }


  .phone_page_middle_first_right_bottom_context {
    text-align: right;
  }

  .phone_page_middle_second {
    width: 100%;
    padding: 0.5rem 0;
    padding-right: 10px;
    background: #417bf0;
    text-align: right;
  }

  .phone_page_middle_second
    .phone_page_middle_first_left_title:nth-child(2)::after {
    content: "";
    display: block;
    width: 30%;
    margin-left: 60%;
    height: 0.05rem;
    background: #fff;
  }

  .phone_page_middle_first_right_bottom_btn {
    margin-left: 60%;
    width: 40%;
    background: #fff;
    border-radius: 0.51rem;
    text-align: center;
    color: #faad14;
  }

  .phone_page_content_right_first_button_bbs {
    margin: 0 auto;
    width: 90%;
    background: #fff;
    border-radius: 0.51rem;
    text-align: center;
    color: #faad14;
  }

  .phone_page_bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 60%;
    margin: 0 auto;
    padding: 1.5rem 0;
  }
  .phone_page_bottom_left {
    width: 45%;
  }

  .phone_page_bottom_right {
    width: 45%;
  }
  .phone_page_content_left_top_icon {
    width: 3.94rem;
    height: 1.3rem;
    margin: 0 auto;
    background: url(../../../assets/newpage/main-index/main_logo.png);
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .phone_page_content_left_middle {
    padding-bottom: 3rem;
    color: #71858b;
    font-size: 0.5rem;
    text-align: center;
  }
}
