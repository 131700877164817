.main_content {
  background: #f6f6f8;
}

.connet_us {
  margin-top: 0.56rem;
  width: 100%;
  height: 10rem;
  background: url("../../../assets/contactus/connectbg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 1.41rem;
  padding-left: 4.14rem;
}

.connet_us_title {
  font-size: 0.48rem;
  color: #ffffff;
}

.connet_us_subtitle {
  padding: 0.16rem 0;
  font-size: 0.18rem;
  color: #ffffff;
}

.connet_us_input_item {
  margin-top: 0.32rem;
  width: 5.91rem;
  height: 0.46rem;
  border-radius: 4px;
}

.connet_us_input_item_textArea {
  margin-top: 0.32rem;
  width: 5.91rem;
  border-radius: 4px;
}

.connet_us_input {
  width: 5.91rem;
  height: 0.46rem;
  border-radius: 4px;
}

.connect_us_btn {
  margin-top: 0.37rem;
  padding-left: 5.4rem;
}

.connect_us_title {
  padding-top: 0.28rem;
  text-align: center;
  font-size: 0.24rem;
  color: #ffffff;
}

.connect_us_subtitle {
  text-align: center;
  font-size: 0.24rem;
  color: #ffffff;
}

.connnect_us_map {
  width: 12.58rem;
  height: 5.48rem;
  margin: 0 auto;
  margin-top: 2.16rem;
  margin-bottom: 0.6rem;
  background: #ffffff;
  border-radius: 8px;
  padding: 0.26rem 0.35rem;
}

.connect_type {
  width: 12rem;
  margin: 0 auto;
  margin-bottom: 2.23rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.connect_type_item {
  text-align: center;
}

.connect_type_item_img {
  margin: 0 auto;
  width: 0.24rem;
  height: 0.24rem;
  background: url("../../../assets/contactus/phone.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.connect_type_item:nth-child(2) .connect_type_item_img {
  margin: 0 auto;
  width: 0.24rem;
  height: 0.24rem;
  background: url("../../../assets/contactus/email.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.connect_type_item:nth-child(3) .connect_type_item_img {
  margin: 0 auto;
  width: 0.24rem;
  height: 0.24rem;
  background: url("../../../assets/contactus/location.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.connect_type_item_title {
  font-size: 0.24rem;
  padding: 0.14rem 0.09rem;
}

.connect_type_item_content {
  font-size: 0.16rem;
  color: rgba(42, 44, 44, 0.65);
}

@media (max-width: 1000px),
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .connect_top {
    background: #ffffff;
  }
  .connet_us {
    margin: 0;
    padding: 10px;
    height: 400px;
  }

  .connet_us_title {
    font-size: 18px;
  }
  .connet_us_subtitle {
    font-size: 14px;
  }

  .connet_us_input_item {
    height: 20px;
    width: 100%;
    margin-top: 20px;
  }

  .connet_us_input {
    height: auto;
  }

  .connet_us_input_item_textArea {
    width: 100%;
    margin-top: 20px;
  }

  .connect_us_btn {
    margin-top: 20px;
    padding-left: 80%;
  }

  .connnect_us_map {
    width: 100%;
    height: 500px;
  }

  .connect_type {
    width: 100%;
    display: block;
    padding-bottom: 20px;
  }

  .connect_type_item {
    margin: 10px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .connect_type_item_img {
    margin: 0;
    width: 24px;
    height: 24px;
  }
  .connect_type_item_content {
    font-size: 12px;
  }

  .connect_type_item:nth-child(2) .connect_type_item_img {
    margin: 0;
    width: 24px;
    height: 24px;
  }

  .connect_type_item:nth-child(3) .connect_type_item_img {
    margin: 0;
    width: 24px;
    height: 24px;
  }

  .connect_type_item_title {
    padding: 0 10px;
  }
}
