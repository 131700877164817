.filter {
  width: 100%;
  background: #ffffff;
}

.filter_lines {
  width: 12rem;
  margin: 0 auto;
}

.filter_lines_main {
  height: 0.91rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter_lines_main_item_active {
  cursor: pointer;
  font-size: 0.2rem;
  color: #18b2b0;
  font-weight: 700;
}

.filter_lines_main_item_active:after {
  content: "";
  display: block;
  width: 0.32rem;
  margin: 0 auto;
  border-bottom: 0.04rem solid #3fd5d3;
  box-shadow: 0px 1px 6px rgba(63, 213, 211, 0.45);
}

.filter_lines_main_item {
  cursor: pointer;
  font-size: 0.14rem;
  color: #2a2c2c;
  font-weight: 400;
}

.filter_lines_other_item {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 0.12rem 0;
  border-bottom: 0.01rem solid rgba(42, 44, 44, 0.15);
}

.filter_lines_other_item_first {
  width: 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter_lines_other_item_first_text {
  color: rgba(42, 44, 44, 0.35);
  font-size: 0.12rem;
  font-weight: 400;
  padding-right: 0.3rem;
}

.filter_lines_other_item_second {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.filter_lines_other_item_second_text {
  color: #18b2b0;
  font-size: 0.12rem;
  font-weight: 400;
}

.filter_icon {
  width: 0.04rem;
  height: 0.08rem;
  background: url(../../assets/components/filter/filterarrow.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.filter_lines_other_item_special {
  width: 10%;
  font-size: 0.12rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.85);
}

.filter_lines_other_item_third {
  width: 60%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.filter_lines_other_item_third_item {
  cursor: pointer;
  padding-right: 0.24rem;
  padding-bottom: 0.06rem;
  font-size: 0.12rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.85);
}

.filter_lines_other_item_third_item_active {
  padding-right: 0.24rem;
  padding-bottom: 0.06rem;
  font-size: 0.12rem;
  font-weight: 400;
  color: #18b2b0;
}

.filter_lines_other_item_fouth {
  cursor: pointer;
  width: 10%;
  text-align: right;
  color: rgba(42, 44, 44, 0.65);
  font-size: 0.12rem;
  font-weight: 400;
}

.filter_lines_other_item_fouth_active {
  cursor: pointer;
  width: 10%;
  text-align: right;
  color: #18b2b0;
  font-size: 0.12rem;
  font-weight: 400;
}

.filter_by_sort {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.filter_by_sort_item {
  cursor: pointer;
  width: 0.9rem;
  color: rgba(42, 44, 44, 0.65);
  font-size: 0.12rem;
  font-weight: 400;
  padding: 0.2rem 0;
  text-align: center;
}

.filter_by_sort_item_active {
  cursor: pointer;
  color: #18b2b0;
  font-size: 0.12rem;
  font-weight: 400;
  width: 0.9rem;
  height: 0.24rem;
  text-align: center;
  line-height: 0.24rem;
  background: rgba(63, 213, 211, 0.08);
}
