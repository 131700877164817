.menus {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  height: 100%;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none !important;
}

.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 0.52rem !important;
}

.ant-menu-title-content-main {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.13rem;
  font-weight: 700;
}

.ant-menu-title-content-secondary {
  font-size: 0.12rem;
  font-weight: 400;
  padding-left: 0.16rem;
  color: rgba(42, 44, 44, 0.65);
}

.ant-menu-submenu-title {
  display: flex !important;
  align-items: center !important;
}

.ant-menu-title-content-item {
  padding: 0.1rem;
}

.ant-menu-title-content-lines {
  width: 3.4rem;
  height: 0.005rem;
  background: #dedede;
}

.ant-menu-title-content-context {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ant-menu-title-content-item-main {
  color: #2a2c2c;
  font-size: 0.16rem;
  font-weight: 700;
}

.ant-menu-title-content-context-secondary {
  width: 1.12rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 0.12rem;
  font-weight: 500;
  line-height: 0.25rem;
  color: rgba(42, 44, 44, 0.65);
}

.ant-menu-submenu-active {
  background: #3fd5d3 !important;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  color: #3fd5d3;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover .anticon {
  color: #fff !important;
}

.ant-menu-title-content-item:hover > .anticon {
  color: #fff !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #fff !important;
}

.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #fff !important;
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  background: #3fd5d3 !important;
  color: #fff !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #fff !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-active .ant-menu-title-content-secondary {
  color: #fff !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #fff !important;
}

.ant-menu-light .ant-menu-submenu-active .anticon {
  color: #fff !important;
}

.ant-menu-submenu-active
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #fff !important;
}
