.content {
  background-color: #000;
  height: 100vh;
  width: 100%;
  background: url(../../assets/login/Group\ 3745.png) no-repeat;
  background-size: 100% 100%;
}

.content_Logo {
  cursor: pointer;
  width: 100%;
  padding-left: 3%;
}

.logo_detail {
  height: 30vh;
}

.content_login_box {
  width: 100%;
  padding-right: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.login_box_content {
  width: 700px;
  height: 700px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  background: url(../../assets/login/Group\ 3746.png) no-repeat;
  background-size: 100%;
  background-position: bottom;
}

.box_title {
  width: 59%;
  background-color: #37d0d2;
  height: 50px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.get_code {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input_box_username_get_code {
  width: 150px !important;
  border-radius: 20px !important;
}

.get_code_tag {
  cursor: pointer;
  width: 100px;
  font-size: 13px;
}

.box_title_item {
  cursor: pointer;
  width: 20%;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  border-bottom: 2px solid #37d0d2;
}

.box_title_item_active {
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  border-bottom: 2px solid #ffffff;
}

.box_title_line {
  width: 10%;
  height: 2px;
  margin-top: 10px;
  margin-right: 30%;
  background-color: #37d0d2;
}

.input_box {
  width: 100%;
  padding-top: 30px;
  text-align: center;
  margin: 0 auto;
}

.input_box_username {
  width: 250px !important;
  border-radius: 20px !important;
}

.forget_pass {
  cursor: pointer;
  padding-top: 10px;
  width: 250px;
  margin: 0 auto;
  text-align: right;
  font-size: 10px;
  color: #37d0d2;
}

.forget_pass_left {
  cursor: pointer;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 250px;
  text-align: left;
  font-size: 8px;
  color: #37d0d2;
}

.login_button_box {
  cursor: pointer;
  padding-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box_login_button {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 20px;
  background-color: #37d0d2;
  color: #ffffff;
}

.bottom_content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left_lines {
  width: 15%;
  border-top: 1px solid #e8e6e6;
}

.other_type {
  color: #989797;
  padding: 0 10px;
}

.bottom_icons {
  margin: 0 auto;
  width: 50%;
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.display {
  display: block;
}

.nodisplay {
  display: none;
}

.bottom_radio {
  cursor: pointer;
  padding-top: 10px;
  color: #8c8b8b;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_type {
  color: #37d0d2;
}

.haveaccoutgotoparentlogin {
  display: none;
}

/* 自适应css代码 */

@media (max-width: 768px),
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .content {
    height: 100vh;
    width: 100%;
    background: none;
  }

  .content_Logo {
    background: url(../../assets/login/Group\ 3745.png) no-repeat;
    background-size: 100% 100%;
  }

  .box_title {
    display: none;
  }

  .content_login_box {
    justify-content: center;
    padding-right: 0;
  }

  .login_box_content {
    background: none;
    height: 0;
  }

  .haveaccoutgotoparentlogin {
    display: block;
    padding-top: 20px;
    text-align: center;
  }

  .haveaccoutgotologin {
    display: inline;
    border-bottom: 1px solid #000;
  }
}
