.leftTool {
  position: fixed;
  top: 4.3rem;
  right: 3%;
  width: 0.96rem;
  height: 2.11rem;
  background: url(../../assets/components/left.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.leftTool_item {
    cursor: pointer;
}

.leftTool_item:nth-child(1) {
  width: 100%;
  padding-bottom: 0.2rem;
}

.leftTool_item_img {
  margin: 0 auto;
  width: 0.24rem;
  height: 0.24rem;
  background: url(../../assets/components/phone.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.leftTool_item:nth-child(2) .leftTool_item_img {
  margin: 0 auto;
  width: 0.24rem;
  height: 0.24rem;
  background: url(../../assets/components/home.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.leftTool_item_title {
  font-size: 0.1rem;
  text-align: center;
  color: rgba(42, 44, 44, 0.65);
}
