.pagination {
  width: 100%;
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination-item-active {
  background: #3fd5d3 !important;
  color: #fff !important;
  border-color: #3fd5d3 !important;
}

.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: #3fd5d3 !important;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: unset !important;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #3fd5d3 !important;
  border-color: #3fd5d3 !important;
}
