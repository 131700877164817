.main_content {
  background: #f6f6f8;
}
.yellowpage_menu {
  width: 12rem;
  /* height: 5.09rem; */
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
}

.yellowpage_menu_left {
  width: 32%;
}

.yellowpage_menu_right {
  width: 68%;
}

.yellowpage_menu_right_top {
  width: 100%;
  height: 3.4rem;
}

.yellowpage_menu_right_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yellowpage_content {
  padding-top: 0.725rem;
  width: 12rem;
  margin: 0 auto;
  overflow: hidden;
}

.hot_recommend_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot_recommend_title_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot_recommend_title_right {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot_recommend_title_right_text {
  font-size: 0.16rem;
  font-weight: 400;
  padding-right: 0.175rem;
}

.hot_recommend_title_right_icon {
  width: 0.06rem;
  height: 0.12rem;
  background: url(../../../assets/yellowpage/arrow.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
}

.hot_recommend_title_icon {
  width: 0.15rem;
  height: 0.2rem;
  background: url(../../../assets/yellowpage/hot.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.hot_recommend_title_text {
  padding: 0 0.32rem 0 0.08rem;
  font-size: 0.24rem;
  font-weight: 700;
}

.hot_recommend_title_flag {
  font-size: 0.14rem;
  font-weight: 400;
}

.hot_recommend_content_item {
  margin-right: 0.24rem;
  height: 4.5rem;
}

.hot_recommend_content_item .ant-card-cover {
  height: 2.8rem;
}

.hot_recommend_content_top {
  position: relative;
  top: 0.24rem;
  z-index: 999;
  width: 0.68rem;
  height: 0.24rem;
  background: #3fd5d3;
  border-radius: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no_top {
  background: transparent;
}
.hot_recommend_content_top_icon {
  height: 0.12rem;
  width: 0.1267rem;
  background: url(../../../assets/yellowpage/top.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.hot_recommend_content_top_text {
  padding-left: 0.056rem;
  font-size: 0.12rem;
  color: #ffffff;
}

.card_meta .ant-card-meta-title {
  color: #ffffff !important;
  font-weight: 700;
  font-size: 0.16rem;
}

.card_meta .ant-card-meta-description {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 0.12rem;
}

.normal_shop {
  height: 3.55rem;
  margin-bottom: 0.65rem;
}

.normal_shop_title {
  padding-bottom: 0.255rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.normal_shop_title_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.normal_shop_title_right {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.normal_shop_title_right_text {
  font-size: 0.16rem;
  font-weight: 400;
  padding-right: 0.175rem;
}

.normal_shop_title_right_icon {
  width: 0.06rem;
  height: 0.12rem;
  background: url(../../../assets/yellowpage/arrow.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
}

.normal_shop_title_icon {
  width: 0.15rem;
  height: 0.2rem;
  /* background: url(../../../assets/yellowpage/hot.png); */
  /* background-repeat: no-repeat;
  background-size: 100%; */
}

.normal_shop_title_text {
  padding: 0 0.32rem 0 0.08rem;
  padding-left: 0.21rem;

  font-size: 0.24rem;
  font-weight: 700;
}

.normal_shop_title_flag {
  font-size: 0.14rem;
  font-weight: 400;
}

.normal_shop_content_item {
  margin-right: 0.24rem;
  height: 3.55rem;
}

.normal_shop_content_item .ant-card-cover {
  height: 2.08rem;
}

.normal_shop_content_top {
  position: relative;
  top: 0.24rem;
  z-index: 999;
  width: 0.68rem;
  height: 0.24rem;
  background: #3fd5d3;
  border-radius: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.normal_card_meta .ant-card-meta-title {
  width: 1.6rem;
  font-weight: 400;
  color: #2a2c2c;
  font-size: 0.12rem;
}

.normal_card_meta .ant-card-meta-description {
  font-weight: 400;
  color: rgba(42, 44, 44, 0.65);
  font-size: 0.12rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.yellow_page {
  width: 12rem;
  margin: 0 auto;
  padding-bottom: 0.72rem;
}

.yellow_page_list {
  padding-bottom: 0.72rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.yellow_page_pagine {
  width: 12rem;
  margin: 0 auto;
  text-align: center;
}

.yellow_page_list_left {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.yellow_page_list_left_item {
  cursor: pointer;
  height: 3rem;
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.yellow_page_list_left_item_img {
  width: 4rem;
}

.yellow_page_list_left_item_img_top {
  position: relative;
  top: 0.24rem;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3fd5d3;
  color: #ffffff;
  border-radius: 0.08rem 0rem;
  width: 0.68rem;
  height: 0.24rem;
}

.yellow_page_list_left_item_msg {
  width: 90%;
  height: 3rem;
  overflow: hidden;
  padding-left: 0.4rem;
}

.yellow_page_list_left_item_msg_title {
  color: #2a2c2c;
  font-size: 0.24rem;
  font-weight: bold;
}

.yellow_page_list_left_item_msg_tag {
  padding-top: 0.16rem;
  color: rgba(42, 44, 44, 0.65);
  font-size: 0.18rem;
  font-weight: 400;
}

.yellow_page_list_left_item_msg_box {
  padding-top: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.yellow_page_list_left_item_msg_box_item {
  margin-left: 0.08rem;
  padding: 0 0.1rem;
  height: 0.33rem;
  color: #18b2b0;
  background: rgba(63, 213, 211, 0.08);
  border: 0.01rem solid #3fd5d3;
  box-sizing: border-box;
  border-radius: 0.04rem;
  text-align: center;
  line-height: 0.33rem;
  font-size: 0.16rem;
}

.yellow_page_list_left_item_msg_bottom {
  padding-top: 0.8rem;
  color: rgba(42, 44, 44, 0.65);
  font-size: 0.16rem;
  font-weight: 400;
}

.yellow_page_list_left_item_msg_bottom_time {
  padding: 0.06rem 0;
}

.yellow_page_list_right {
  padding-top: 0.15rem;
}

.yellow_page_list_right_people_like {
  border: 0.01rem solid #c2c3c5;
  padding: 0.15rem;
  margin-top: 0.3rem;
}

.yellow_page_list_right_people_like_title {
  color: rgba(42, 44, 44, 0.85);
  font-size: 0.12rem;
}

.yellow_page_list_right_people_like_item {
  cursor: pointer;
  width: 2.18rem;
  margin-top: 0.32rem;
}

.yellow_page_list_right_people_like_item_img {
  width: 2.18rem;
}

.yellow_page_list_right_people_like_item_text_title {
  color: #18b2b0;
  font-size: 0.14rem;
  text-align: center;
}

.yellow_page_list_right_people_like_item_text_msg {
  color: rgba(42, 44, 44, 0.65);
  text-align: center;
}

.yellow_page_detail {
  background-color: #ffffff;
}

.yellow_page_detail_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.32rem;
  /* border-bottom: 0.01rem solid; */
  box-shadow: 0px 1px 0px #dfdfdf;
}

.yellow_page_detail_top_left {
  width: 90%;
}

.yellow_page_detail_top_left_title {
  font-size: 0.24rem;
  font-weight: 700;
}

.yellow_page_detail_top_left_createtime {
  font-size: 0.16rem;
  font-weight: 400;
  color: #2a2c2ca6;
  padding: 0.12rem 0;
}

.yellow_page_detail_top_left_bottom {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.yellow_page_detail_top_left_bottom_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.16rem;
  font-weight: 400;
}

.yellow_page_detail_top_left_bottom_item:nth-child(1) {
  color: #2a2c2ca6;
}

.yellow_page_detail_top_left_bottom_item:nth-child(2) {
  color: #18b2b0;
}

.yellow_page_detail_top_left_bottom_item:nth-child(3) {
  padding-left: 0.08rem;
  color: #18b2b0;
}

.yellow_page_detail_top_left_bottom_item_icon {
  margin-right: 0.08rem;
  display: block;
  width: 0.16rem;
  height: 0.16rem;
  background: url(../../../assets/yellowpage/icon3.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.yellow_page_detail_top_left_bottom_item_icon2 {
  margin-right: 0.08rem;
  display: block;
  width: 0.16rem;
  height: 0.16rem;
  background: url(../../../assets/yellowpage/icon4.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.yellow_page_detail_top_right {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.yellow_page_detail_top_right_item {
  cursor: pointer;
}

.yellow_page_detail_top_right_item_icon1 {
  margin: 0 auto;
  width: 0.2rem;
  height: 0.2rem;
  background: url(../../../assets/yellowpage/icon1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.yellow_page_detail_top_right_item_icon2 {
  margin: 0 auto;
  width: 0.19rem;
  height: 0.19rem;
  background: url(../../../assets/yellowpage/icon2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.yellow_page_detail_top_right_item_icon3 {
  margin: 0 auto;
  width: 0.19rem;
  height: 0.19rem;
  background: url(../../../assets/yellowpage/icon1_active.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.yellow_page_detail_top_right_item_name {
  padding-top: 0.1rem;
  font-size: 0.16rem;
  color: #2a2c2ca6;
}

.yellow_page_detail_middle {
  padding: 00.32rem;
}

.yellow_page_detail_middle_content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.yellow_page_detail_middle_content_left {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.yellow_page_detail_middle_content_left_img_main {
  width: 3.24rem;
}

.yellow_page_detail_middle_content_left_img_second {
  width: 3.24rem;
}

.yellow_page_detail_middle_content_left_contnet {
  padding-left: 0.26rem;
}

.yellow_page_detail_middle_content_left_contnet_type {
  font-size: 0.14rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.65);
}

.yellow_page_detail_middle_content_left_contnet_region {
  padding: 0.24rem 0;
  font-size: 0.14rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.65);
}

.yellow_page_detail_middle_content_left_contnet_connectpeople {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.14rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.65);
}

.yellow_page_detail_middle_content_left_contnet_connectpeople_icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.yellow_page_detail_middle_content_left_contnet_connectpeople_icon_img {
  padding: 0.07rem;
  background-color: #21b2ee;
}

.yellow_page_detail_middle_content_left_contnet_connectpeople_icon_img_icons {
  width: 0.16rem;
  height: 0.16rem;
  background-image: url(../../../assets/yellowpage/icon5.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.yellow_page_detail_middle_content_left_contnet_connectpeople_icon_text {
  font-size: 0.12rem;
  color: #ffffff;
  padding: 0.06rem 0.1rem;
  background: #56c6f1;
  border-radius: 0px 4px 4px 0px;
}

.yellow_page_detail_middle_content_left_contnet_mail {
  padding: 0.24rem 0;
  font-size: 0.14rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.65);
}

.yellow_page_detail_middle_content_left_contnet_address {
  font-size: 0.14rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.65);
}

.yellow_page_detail_middle_content_left_contnet_phone {
  width: 1.7rem;
  margin-top: 1rem;
  padding: 0.08rem 0.18rem;
  background: #3fd5d3;
  border: 0.01rem solid #3fd5d3;
  box-sizing: border-box;
  border-radius: 0.04rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yellow_page_detail_middle_content_left_contnet_phone_icon {
  width: 0.1763rem;
  height: 0.1763rem;
  margin-right: 0.0418rem;
  background: url(../../../assets/yellowpage/icon7.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.yellow_page_detail_middle_content_left_contnet_phone_number {
  font-size: 0.14rem;
  font-weight: 400;
  color: #ffffff;
}

.yellow_page_detail_middle_content_right_map_title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.yellow_page_detail_middle_content_right_map {
  width: 2.86rem;
  height: 2.5rem;
}

/* .ant-card-meta-title {
  overflow: visible !important;
  white-space: normal !important;

} */

.yellow_page_detail_middle_content_right_map_icon {
  width: 0.16rem;
  height: 0.16rem;
  background: url(../../../assets/yellowpage/icon6.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.yellow_page_detail_middle_bottom_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.1rem 0.32rem;
  background-color: rgba(63, 213, 211, 0.08);
}

.yellow_page_detail_middle_bottom_title_item_icon {
  margin-right: 0.0933rem;
  width: 0.133rem;
  height: 0.133rem;
  background: url(../../../assets/yellowpage/icon8.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.yellow_page_detail_middle_bottom_title_item {
  display: flex;
  align-items: center;
  font-size: 0.12rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.65);
}

.yellow_page_detail_middle_bottom_content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
}

.yellow_page_detail_middle_bottom_content_item {
  text-align: center;
  padding: 0.32rem 0;
}

.yellow_page_detail_middle_bottom_content_item_icon {
  width: 0.39rem;
  height: 0.39rem;
  margin: 0 auto;
  background: url(../../../assets/yellowpage/icon9.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.yellow_page_detail_middle_bottom_content_item_title {
  font-size: 0.16rem;
  color: rgba(42, 44, 44, 0.65);
}

.yellow_page_detail_middle_bottom_content_item_content {
  font-size: 0.12rem;
  color: rgba(42, 44, 44, 0.65);
}

.yellow_page_detail_middle_bottom_list {
  margin-top: 0.32rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.yellow_page_detail_middle_bottom_list_left {
  width: 75%;
  background: #ffffff;
}

.yellow_page_detail_middle_bottom_list_left_title {
  padding: 0.24rem 0.32rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 1px 0px #dfdfdf;
}

.yellow_page_detail_middle_bottom_list_left_title_item_active {
  font-size: 0.24rem;
  font-weight: 700;
  color: #3fd5d3;
}

.yellow_page_detail_middle_bottom_list_left_title_item_active::after {
  content: '';
  display: block;
  width: 0.32rem;
  margin: 0 auto;
  border-bottom: 0.04rem solid #3fd5d3;
  box-shadow: 0px 1px 6px rgba(63, 213, 211, 0.45);
}

.yellow_page_detail_middle_bottom_list_left_title_item {
  margin-left: 0.32rem;
  font-size: 0.16rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.65);
}

.yellow_page_detail_middle_bottom_list_left_content {
  padding: 0.72rem 0.74rem 1.56rem 0.32rem;
}

.yellow_page_detail_middle_bottom_list_left_content_item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.yellow_page_detail_middle_bottom_list_left_content_item:nth-child(1) {
  margin-bottom: 0.72rem;
}

.yellow_page_detail_middle_bottom_list_left_content_item_left {
  width: 20%;
}

.yellow_page_detail_middle_bottom_list_left_content_item_left_icon {
  width: 0.82rem;
  height: 0.82rem;
  margin: 0 auto;
  background: url(../../../assets/yellowpage/icon10.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.yellow_page_detail_middle_bottom_list_left_content_item_left_title {
  font-size: 0.16rem;
  font-weight: 400;
  color: #2a2c2c;
  text-align: center;
}

.yellow_page_detail_middle_bottom_list_left_content_item_right {
  width: 80%;
  font-size: 0.14rem;
  padding: 0.24rem 0.32rem;
  background: #fbfbfb;
}

.yellow_page_detail_middle_bottom_list_left_content_item_left_icon2 {
  width: 0.82rem;
  height: 0.82rem;
  margin: 0 auto;
  background: url(../../../assets/yellowpage/icon11.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.yellow_page_detail_middle_bottom_list_left_content_item_right2 {
  width: 80%;
  font-size: 0.14rem;
}

.yellow_page_detail_middle_bottom_list_left_content_item_right_item_title {
  font-size: 0.16rem;
  color: #2a2c2c;
  font-weight: 400;
}

.yellow_page_detail_middle_bottom_list_left_content_item_right_item_tags {
  font-size: 0.16rem;
  color: rgba(42, 44, 44, 0.65);
}

.yellow_page_detail_middle_bottom_list_left_content_item_right_item_tags_text {
  font-size: 0.12rem;
  padding-left: 0.12rem;
}

.yellow_page_detail_middle_bottom_list_right {
  width: 23%;
  background: #ffffff;
}

.yellow_page_detail_middle_bottom_list_right_title {
  padding: 0.33rem 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 0px #dfdfdf;
}

.yellow_page_detail_middle_bottom_list_right_title_item {
  text-align: center;
  font-size: 00.16rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.65);
}

.yellow_page_detail_middle_bottom_list_right_content_item {
  cursor: pointer;
  padding: 0 0.4rem;
  padding-bottom: 0.32rem;
}

.yellow_page_detail_middle_bottom_list_right_content_item:nth-child(1) {
  padding: 0.32rem 0.4rem;
}

.yellow_page_detail_middle_bottom_list_right_content_item_img {
  width: 1.32rem;
  margin: 0 auto;
}

.yellow_page_detail_middle_bottom_list_right_content_item_title {
  text-align: center;
  font-size: 0.16rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.65);
}

.yellow_page_detail_middle_bottom_list_right_content_item_desc {
  text-align: center;
  font-size: 0.12rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.65);
}

.chat_people_message_right {
  width: 100%;
}

.chat_people_message_right_message {
  padding-bottom: 0.5rem;
  width: 100%;
  height: 5.8rem;
  max-height: 5.8rem;
  overflow: auto;
  padding-right: 0.2rem;
}

.people_message_right_message_right_text_content {
  /* background: rgba(42, 44, 44, 0.06); */
  padding: 0 0.1rem;
}

.right_ads {
  position: fixed;
  left: 0.5rem;
  top: 4rem;
  width: 1.4rem;
  z-index: 199;
}

@media (max-width: 1000px),
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .shop_content {
    padding: 0.5rem 0;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 8rem;
  }

  .shop_content_left {
    width: 50%;
    min-height: 8rem;
  }

  .shop_content_right {
    width: 50%;
    word-break: break-all;
  }

  .shop_content_hot_right {
    padding-top: 20px;
    width: 50%;
    word-break: break-all;
  }

  .shop_content_right_title_name {
    font-weight: 700;
    font-size: 0.9rem;
  }

  .shop_content_right_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .shop_content_right_title_asked {
    color: #42d8d9;
  }

  .shop_content_right_item {
    padding: 0.3rem 0;
  }

  .shop_content_right_item_title {
    width: 40%;
    padding-left: 0.3rem;
  }

  .phone_shop_gird_icons_item_icon_item {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/shop1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_shop_gird_icons_item_icon_item2 {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/shop2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_shop_gird_icons_item_icon_item3 {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/shop3.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_shop_gird_icons_item_icon_item4 {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/shop4.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_shop_gird_icons_item_icon_item5 {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/shop5.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_shop_gird_icons_item_icon_item6 {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/shop6.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_shop_gird_icons_item_icon_item7 {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/shop7.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_shop_gird_icons_item_icon_item8 {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/shop8.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .shop_content_left_top {
    position: relative;
    top: 20px;
    z-index: 99;
    background: orange;
    width: 40px;
    text-align: center;
    border-radius: 4px;
  }

  .phone_shop_content_item_top {
  }

  .phone_shop_content_item_top_name {
    font-size: 20px;
    font-weight: 700;
  }

  .phone_shop_content_item_top_time {
    color: #42d8d9;
  }

  .phone_shop_content_item_top_tag {
    font-size: 16px;
    font-weight: 400;
  }

  .phone_shop_content_item_top_connect {
    font-size: 16px;
    font-weight: 700;
    padding-top: 20px;
  }

  .phone_shop_content_item_top_connect_item {
    padding-top: 10px;
  }

  .phone_shop_content_item_top_connect_map {
    padding-top: 10px;
    height: 200px;
    width: 100%;
  }

  .phone_shop_content_item_top_connect_imgs {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .phone_shop_content_item_imgs_item {
    width: 100px;
    margin-right: 10px;
  }

  .phone_bbs_detail_buttom {
    width: 100%;
    padding: 10px 0;
    background: #ffffff;
  }

  .phone_bbs_detail_buttom_btn {
    width: 150px;
    margin: 10px auto;
    background: #04f2d8;
    border-radius: 20px;
    padding: 10px 20px;
    text-align: center;
  }

  .phone_bbs_swipers {
    position: sticky;
    top: 0;
    z-index: 199;
  }

  .phone_yellow_content {
    background: #ffffff;
    width: 95%;
    margin: 0 auto;
    padding: 0.2rem;
    border-radius: 0.5rem;
    /* margin-top: -2rem; */
  }

  .phone_bbs_second_category {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: -1rem;
  }

  .phone_bbs_second_category_item {
    color: #00ab82;
    width: 22%;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    text-align: center;
    border-radius: 8px;
    background: #ffffff;
  }

  .phone_bbs_second_category_item_active {
    color: #ffffff;
    width: 22%;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    text-align: center;
    border-radius: 8px;
    background: #00ab82;
  }

  .phone_yellow_buttons {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .phone_yellow_buttons_item {
    color: #00ab82;
    width: 22%;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    text-align: center;
    border-radius: 8px;
    background: #ffffff;
  }
}
