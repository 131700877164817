.foot {
  height: 5.19rem;
  width: 100%;
  background: #ffffff;
}

.foot_content {
  padding-top: 0.4rem;
  width: 12rem;
  margin: 0 auto;
}

.foot_logo {
  width: 2.05rem;
  height: 0.68rem;
  margin: 0 auto;
  background: url(../../assets/components/logo.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.foot_menu {
  width: 7rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.foot_menu_item {
  cursor: pointer;
  font-size: 0.2rem;
  font-weight: 700;
  color: rgba(42, 44, 44, 0.85);
}

.foot_menu_item_line {
  height: 0.18rem;
  width: 1px;
  background: rgba(42, 44, 44, 0.85);
}

.foot_line {
  margin-top: 0.32rem;
  width: 12rem;
  height: 1px;
  background: rgba(42, 44, 44, 0.15);
}

.foot_link {
  width: 2.2rem;
  margin: 0 auto;
  margin-top: 0.32rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.foot_connect {
  width: 12rem;
  margin: 0 auto;
  margin-top: 0.24rem;
  text-align: center;
}

.foot_connect_us {
  font-size: 0.16rem;
  color: rgba(42, 44, 44, 0.85);
  font-weight: 400;
}

.foot_connect_email {
  margin-top: 0.16rem;
  font-size: 0.12rem;
  color: rgba(42, 44, 44, 0.35);
  font-weight: 400;
}

.foot_connect_phone {
  font-size: 0.12rem;
  color: rgba(42, 44, 44, 0.35);
  font-weight: 400;
}

.foot_connect_adress {
  margin-top: 0.32rem;
  font-size: 0.16rem;
  color: rgba(42, 44, 44, 0.85);
  font-weight: 400;
}

.foot_connect_adressdetail {
  font-size: 0.12rem;
  font-weight: 400;
  color: rgba(42, 44, 44, 0.35);
}

.foot_bottom {
  margin-top: 0.34rem;
  background: #298a89;
  width: 100%;
  text-align: center;
  height: 1.14rem;
  display: table;
}

.foot_bottom_content {
  display: table-cell;
  vertical-align: middle;
}

.foot_bottom_title {
  font-size: 0.2rem;
  font-weight: 700;
  color: #ffffff;
}

.foot_bottom_title_bottom {
  font-size: 0.12rem;
  color: #ffffff;
}

.foot_connect_item {
  cursor: pointer;
  animation:mymove 5s infinite;
}
