.bread_crumb {
    width: 12rem;
    margin: 0 auto;
    padding: 0.24rem 0;
}


.ant-breadcrumb > span:last-child {
    color: #18B2B0 !important;
}

.ant-breadcrumb {
    cursor: pointer !important;
}