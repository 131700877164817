.ad {
  width: 100%;
  height: 4.05rem;
  background: url(../../assets/bbs/bottom.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.ad_top {
  width: 100%;
  text-align: center;
  font-size: 0.24rem;
  font-weight: 700;
  color: #fff;
}

.ad_middle {
  width: 100%;
  padding: .16rem 0;
  text-align: center;
  font-size: 0.14rem;
  font-weight: 400;
  color: #fff;
}


.ad_bottom {
    width: 10.54rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}