.message_list_top {
    padding: 10px;
   display: flex; 
   align-items: center;
}

.message_list_top_left {
    width: 45%;
}

.message_list_content_item {
    padding:10px 10px;
    display: flex;
    align-items: flex-start
}

.message_list_content_item_text {
    width: 95%;
    padding: 0 10px;
    display: flex;
    align-items: flex-start
}

.message_list_content_item_titles {
    width: 75%;
    
}

.message_list_content_item_title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.message_list_content_item_title img {
    width: 100px;
}

.message_list_content_item_time {
    text-align: center;
}



