
.message_detail {
}
.message_detail_top {
  position: fixed;
  height: 50px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  background: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message_detail_top_middle {
  text-align: center;
}

.message_detail_content {
  padding: 50px 10px;
  height: 90vh;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.message_detail_content_left {
  text-align: left;
  padding: 10px;
  display: flex;
  align-items: flex-start;
}

.message_detail_content_left_item {
  width: 90%;
  padding: 5px;
}

.message_detail_content_right {
  text-align: right;
  display: flex;
  align-items: flex-start;
  padding: 10px;
}

.message_detail_content_right_text_item {
  width: 90%;
  padding-right: 10px;
  border-radius: 5px;
  color: #fff;
}

.message_detail_content_right_text {
  background-color: #6d9cf8;
  padding: 10px;
  display: inline-flex;
}

.message_detail_content_left img {
  width: 200px;
  height: 200px;
}

.message_detail_content_right img {
  width: 200px;
  height: 200px;
}

.message_detail_bottom {
  /* position: fixed;
  height: 34px;
  left: 0;
  right: 0;
  bottom: 0px; */
  /* position: relative; */
  width: 100%;
  height: 34px;
  background: #fff;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.send_box {
  background-color: #f8f8f8;
  padding: 10px 20px;
  border-radius: 15px;
  width: 80%;
}

.send_box_btn {
}
