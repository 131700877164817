.main_content {
  background: #f6f6f8;
}
.bbs_menu {
  width: 12rem;
  height: 5.09rem;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.bbs_menu_left {
  background: #ffffff;
  height: 5.09rem;
  width: 32%;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.bbs_menu_right_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.bbs_menu_right_top_item2 {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-left: 0.2rem;
  width: 3.86rem;
  height: 2.97rem;
  background: #ffffff;
}

.bbs_menu_right_top_item {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-left: 0.2rem;
  padding: 0.24rem;
  width: 3.86rem;
  height: 2.97rem;
  background: #ffffff;
  overflow: hidden;
}

.bbs_menu_right_top_item_top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 0.1rem;
  border-bottom: 1px solid rgba(63, 213, 211, 0.65);
}

.bbs_menu_right_top_item_top_name {
  font-size: 0.16rem;
  color: #18b2b0;
  padding-left: 0.13rem;
}

.bbs_menu_right_top_item_middle {
  padding: 0.12rem 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bbs_menu_right_top_item_middle_item {
  cursor: pointer;
  padding-bottom: 0.12rem;
  width: 32%;
  text-align: center;
  color: #2a2c2c;
  font-size: 0.14rem;
  border-bottom: 0.04rem solid rgba(63, 213, 211, 0.16);
}

.bbs_menu_right_top_item_middle_item_active {
  cursor: pointer;
  padding-bottom: 0.12rem;
  width: 32%;
  text-align: center;
  color: #2a2c2c;
  font-size: 0.14rem;
  border-bottom: 0.04rem solid #3fd5d3;
}

.bbs_menu_right_top_item_bottom_item {
  cursor: pointer;
  padding-bottom: 0.1rem;
  color: #2a2c2c;
  font-size: 0.12rem;
  width: 3.38rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bbs_menu_right_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.bbs_menu_right_bottom_item {
  width: 3.86rem;
  height: 1.88rem;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
}

.bbs_main_content {
  width: 12rem;
  margin: 0.4rem auto;
  border-radius: 8px;
}

.bbs_main_content_top_new {
  padding: 0.16rem 0.32rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bbs_main_content_top_new_button {
  cursor: pointer;
  text-align: center;
  width: 1.18rem;
  padding: 0.1rem;
  background: #18b2b0;
  border-radius: 4px;
  font-size: 0.16rem;
  color: #ffffff;
}

.bbs_main_content_top_new_button:hover {
  background-color: #32d3d0;
}

.bbs_main_content_top_column {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: rgba(63, 213, 211, 0.08);
  padding: 0.15rem 0;
}

.bbs_main_content_top_column_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(42, 44, 44, 0.65);
  font-size: 0.14rem;
}

.bbs_main_content_top_column_item:nth-child(1) {
  width: 80%;
  padding-left: 0.42rem;
}

.bbs_main_content_top_column_item:nth-child(2) {
  width: 10%;
}

.bbs_main_content_top_column_item:nth-child(3) {
  width: 10%;
}

.bbs_main_content_top_column_item_text {
  padding-right: 0.2rem;
  font-size: 0.14rem;
  color: #2a2c2c;
}

.bbs_main_content_top_row {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  padding: 0.15rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.bbs_main_content_top_row_item {
  color: #2a2c2c;
}

.bbs_main_content_top_row_item_active {
  color: #fd4b71;
}

.bbs_main_content_top_row_item:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  font-size: 0.14rem;
  padding-left: 0.1rem;
}

.bbs_main_content_top_row_item:nth-child(2) {
  width: 10%;
  font-size: 0.12rem;
  color: rgba(42, 44, 44, 0.65);
}

.bbs_main_content_top_row_item:nth-child(3) {
  width: 10%;
  font-size: 0.12rem;
  color: rgba(42, 44, 44, 0.65);
}

.bbs_main_content_top_row_item_num {
  color: rgba(42, 44, 44, 0.65);
  padding-left: 0.1rem;
  font-size: 0.12rem;
}

.bbs_main_content_top_row_item_arr {
  color: #18b2b0;
}

.bbs_main_content_context {
  margin-top: 0.4rem;
}

.bbs_main_content_context_top {
  font-size: 0.16rem;
  padding: 0.135rem 0.32rem;
  background: #fff3da;
  border: 1px solid #ffde9f;
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
}

.bbs_main_content_context_item {
  cursor: pointer;
  background: #ffffff;
  padding: 0.16rem 0rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.bbs_main_content_context_item_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bbs_main_content_context_item_top_item:nth-child(1) {
  width: 80%;
  padding-left: 0.32rem;
  font-size: 0.16rem;
  color: #2a2c2c;
}

.bbs_main_content_context_item_top_item:nth-child(2) {
  width: 10%;
  font-size: 0.12rem;
  color: rgba(42, 44, 44, 0.65);
}

.bbs_main_content_context_item_top_item:nth-child(3) {
  width: 10%;
  font-size: 0.12rem;
  color: rgba(42, 44, 44, 0.65);
}

.bbs_main_content_context_item_content_text {
  /* height: 0.44rem; */
  display: flex;
  align-items: center;
}

.bbs_main_content_context_item_content_text img {
  width: 50%;
}

.bbs_main_content_context_item_content_text_content {
  width: 70%;
}

.bbs_main_content_context_item_content_seemore {
  width: 30%;
  height: 0.2rem;
  color: #ffb10e;
  font-size: 0.12rem;
}

.bbs_main_content_context_item_content_text p {
  margin: 0;
  height: 0.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bbs_main_content_context_item_content_imgs {
  padding-top: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bbs_main_content_context_item_content_imgs_item {
  margin-right: 0.1rem;
  width: 10%;
}

.bbs_main_content_context_item_content {
  padding: 0.16rem 0.32rem;
}

.bbs_main_content_context_top_green {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.16rem;
  padding: 0.135rem 0.32rem;
  background: #3fd5d3;
  border: 1px solid #3fd5d3;
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
}

.bbs_main_content_context_top_blue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.16rem;
  padding: 0.135rem 0.32rem;
  background: #56c6f1;
  border: 1px solid #56c6f1;
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
}

.bbs_main_content_context_top_green_right {
  cursor: pointer;
  color: #ffffff;
}

.bbs_ads {
  width: 12rem;
  margin: 0 auto;
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bbs_list_top {
  width: 12rem;
  margin: 0 auto;
}

.bbs_main_content_top_new_right {
  width: 50%;
  text-align: right;
}

.bbs_list_content {
  width: 12rem;
  margin: 0 auto;
  /* margin-bottom: 0.72rem; */
}

.bbs_paginon {
  width: 12rem;
  margin: 0 auto;
  padding: 0.4rem 0;
  background: #ffffff;
  text-align: center;
}

.bbs_style {
  background: #ffffff;
  border-bottom: 1px solid #dfdfdf;
}

.bbs_time {
  padding-left: 0.24rem;
}

.bbs_page_detail_top_right_item_name {
  text-align: center;
  font-size: 0.16rem;
  padding: 0.19rem 0;
}

.bbs_page_detail_top_right_item_bottom {
  color: #18b2b0;
  font-size: 0.12rem;
}

.bbs_detail_text {
  background: #ffffff;
  padding: 0.32rem;
}

.bbs_detail_text img {
  width: 100%;
}

.bbs_detail_list_item_bottom_right_content {
  line-height: 30px;
  font-size: 0.16rem;
  color: #7c7c7c;
  font-weight: bold;
  padding-bottom: 0.2rem;
}

.bbs_detail_reply {
  margin: 0.1rem 0;
  display: flex;
  align-items: f;
  justify-content: space-between;
}

.bbs_detail_reply_left_top {
  overflow: hidden;
  padding: 0.12rem 0.16rem;
  background: rgba(63, 213, 211, 0.08);
  box-shadow: 0px 1px 0px #dfdfdf;
}

.bbs_detail_reply_left {
  background: #ffffff;
  width: 1.9rem;
}

.bbs_detail_reply_left_bottom {
  height: 2.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.bbs_detail_reply_left_bottom_item {
  width: 100%;
  font-size: 0.14rem;
  font-weight: 700;
  color: #2a2c2c;
  padding-top: 0.16rem;
  text-align: center;
}

.bbs_detail_reply_right {
  width: 9.94rem;
  min-height: 3.1rem;
  padding: 0 0.24rem;
  background: #ffffff;
}

.bbs_detail_reply_right_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.4rem;
  padding: 0.12rem 0.16rem;
  padding-top: 0;
  box-shadow: 0px 1px 0px #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
}

.bbs_detail_reply_right_bottom {
  min-height: 2.22rem;
  padding: 0.24rem 0;
}

.bbs_detail_reply_right_bottom_otherreply {
  background: #f9f9f9;
  padding: 0.16rem 0.48rem;
}

.bbs_detail_reply_right_bottom_otherreply_title {
  color: rgba(42, 44, 44, 0.35);
  padding-bottom: 0.06rem;
}

.bbs_detail_reply_right_bottom_otherreply_text {
  color: rgba(42, 44, 44, 0.65);
}

.bbs_detail_reply_right_bottom_content {
  margin-top: 0.12rem;
  color: #2a2c2c;
  font-size: 0.15rem;
}

.bbs_detail_reply_right_bottom_content img {
  width: 1.8rem;
  height: 1.04rem;
}

.bbs_detail_reply_right_bottom_nail {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.12rem 0.16rem;
  padding-top: 0;
  height: 0.48rem;
  border-top: 1px solid #dfdfdf;
  box-shadow: 0px -1px 0px #dfdfdf;
}

.bbs_reply_content {
  padding: 0.32rem 0.4rem;
  background: #ffffff;
  margin-top: 0.16rem;
}

.my-editor {
  overflow: hidden;
  height: 1.78rem !important;
  width: 8.69rem;
  border: 1px solid rgba(42, 44, 44, 0.15);
}

.my_new_editor {
  overflow: hidden;
  height: 1.78rem !important;
  width: 5.9rem;
  border: 1px solid rgba(42, 44, 44, 0.15);
}

.bbs_reply_content_bottom {
  padding-top: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bbs_reply_content_bottom_btn {
  cursor: pointer;
  width: 0.72rem;
  height: 0.32rem;
  font-size: 0.14rem;
  background: #18b2b0;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  line-height: 0.32rem;
}

.bbs_reply_content_bottom_checkbox {
  padding-left: 0.27rem;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #18b2b0 !important;
  border-color: #18b2b0 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #18b2b0 !important;
}

.bf-content {
  height: 1.46rem !important;
}

.bbs_new {
  width: 12rem;
  margin: 0 auto;
  margin-bottom: 0.72rem;
}

.bbs_new_tabs {
  padding: 0.2rem 0.42rem;
  background: #ffffff;
}

.ant-steps-navigation .ant-steps-item {
  text-align: left !important;
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  width: 60% !important;
  background-color: #18b2b0 !important;
}

.icon_name {
  color: #2a2c2c;
}

.ant-steps-item-active .icon_name {
  color: #18b2b0 !important;
}

.ant-steps-navigation .ant-steps-item::before {
  width: 60% !important;
  left: 0 !important;
  background-color: rgba(42, 44, 44, 0.15) !important;
}

.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: 0 !important;
}

.ant-steps-navigation .ant-steps-item-container {
  margin-left: 0 !important;
}

.ant-steps-navigation .ant-steps-item::after {
  content: none !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: none !important;
}

.ant-steps-item-icon {
  border: none !important;
}

.bbs_new_category {
  padding: 1.04rem 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bbs_new_category_item {
  cursor: pointer;
  flex: 1 1 10;
  font-size: 0.16rem;
  color: rgba(42, 44, 44, 0.85);
}

.bbs_new_category_item_active {
  cursor: pointer;
  flex: 1 1 10;
  font-size: 0.16rem;
  color: #18b2b0;
}

.special_content_item {
  margin-top: 0.24rem;
  padding: 0.4rem;
  background: #ffffff;
}

.special_content_item_title {
  padding-bottom: 0.08rem;
  font-size: 0.2rem;
  color: #18b2b0;
  margin-bottom: 0.32rem;
  border-bottom: 1px solid rgba(42, 44, 44, 0.15);
}

.form_tail {
  background: #ffffff;
}

.subButton {
  background: #18b2b0 !important;
  border-color: #18b2b0 !important;
  width: 1.7rem;
  border-radius: 0.04rem;
  margin-top: 0.24rem;
}

.bbs_new_content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.left_form {
  width: 75%;
}

.right_from {
  margin-top: 0.24rem;
  width: 20%;
  padding: 0.4rem 0.45rem;
  background: #ffffff;
}

.ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #18b2b0 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #18b2b0 !important;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-subtitle,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-description {
  color: #18b2b0 !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #18b2b0 !important;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: #18b2b0 !important;
}

.verify_area {
  cursor: pointer;
}

@media (max-width: 1000px),
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .bbs_detail_list_item_bottom_right_content {
      line-height: 30px;
      font-size: 16px;
      color: #7c7c7c;
      font-weight: bold;
      padding-bottom: 10px;
    }
  .bbs_phone_content {
    background: #f7f7f7;
  }
  .phone_bbs_index_top {
    padding: 1rem 1rem;
    background: linear-gradient(#42d8d9, #e4f0ed);
    width: 100%;
    height: 12rem;
    border-radius: 0 0 1rem 1rem;
  }

  .phone_bbs_index_top_first {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .phone_bbs_index_top_first_left {
    display: flex;
    align-items: center;
  }

  .phone_bbs_index_top_first_left_img {
    width: 1rem;
    height: 1rem;
    background: url(../../../assets/main/fire.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_bbs_index_top_first_left_text {
    font-size: 10px;
  }

  .phone_bbs_index_top_first_right {
    width: 112px;
    height: 20px;
    background: url(../../../assets/main/banner1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_bbs_index_search {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .phone_bbs_index_search_avatar {
    width: 15%;
  }

  .phone_bbs_index_search_input {
    width: 60%;
  }

  .sorterList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .sorter_item {
    width: 30%;
    text-align: center;
    padding: 10px 0;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 0.6rem;
    font-weight: 700;
    background: #f3f3f3;
  }

  .sorter_item2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 0.6rem;
    font-weight: 700;
    background: #f3f3f3;
  }

  .adm-dropdown {
    background: none;
  }

  .adm-dropdown-item .adm-dropdown-item-title {
    padding-left: 0;
    padding-right: 0;
  }

  .adm-dropdown-item .adm-dropdown-item-title:after {
    content: "";
    border: none;
  }

  .adm-dropdown-item-active {
    color: #000;
  }

  .phone_bbs_index_search_icons {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .phone_bbs_index_tabs {
    padding-top: 1rem;
    overflow-x: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .phone_bbs_index_tabs_item {
    flex-basis: auto;
    flex-shrink: 0;
    text-align: center;
    padding-right: 1rem;
  }

  .phone_bbs_index_tabs_item_title {
    font-size: 0.8rem;
    color: #5a5a5a;
  }

  .phone_bbs_index_tabs_item_title_active {
    font-size: 0.9rem;
    color: #000;
    font-weight: 700;
  }

  .phone_bbs_index_tabs_item_subtitle::after {
    content: "";
    display: block;
    width: 2rem;
    height: 1rem;
  }

  .phone_bbs_index_tabs_item_subtitle_active {
    font-weight: 700;
  }
  .phone_bbs_index_tabs_item_subtitle_active::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 2rem;
    height: 1rem;
    background: url(../../../assets/phone/aftericon.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_bbs_gird_icons {
    margin-top: -1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .phone_bbs_gird_icons_item {
    width: 25%;
    text-align: center;
  }

  .phone_bbs_gird_icons_item_icon {
    /* background: #ffffff; */
    border-radius: 1.2rem;
  }

  .phone_bbs_gird_icons_item_icon_item {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/phone1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_bbs_gird_icons_item_icon_item2 {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/phone2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_bbs_gird_icons_item_icon_item3 {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/phone3.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_bbs_gird_icons_item_icon_item4 {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/phone4.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .phone_bbs_swipers {
    width: 100%;
    margin: 1rem auto;
    border-radius: 0.5rem;
  }

  .phone_bbs_swipers_item {
    height: 5rem;
    background: chartreuse;
    border-radius: 0.5rem;
  }

  .phone_bbs_content {
    background: #ffffff;
    width: 95%;
    margin: 0 auto;
    padding: 0.2rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
  }

  .phone_bbs_content_title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
  }

  .phone_content_sort_item {
    width: 3rem;
    text-align: center;
    font-size: 0.5rem;
    padding: 0.15rem;
    margin: 0.5rem;
    background: #e3faf3;
    color: #3fbf9e;
  }

  .phone_content_item {
    padding-bottom: 1rem;
  }

  .phone_content_item_top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .phone_content_item_title {
    width: 65%;
  }

  .phone_content_item_title_text {
    font-size: 0.8rem;
    font-weight: 700;
  }

  .phone_content_item_title_time {
    font-size: 0.5rem;
  }

  .phone_content_item_tag {
    width: 15%;
  }

  .phone_content_item_tag_btn {
    font-size: 12px;
    margin-right: 0.1rem;
    text-align: center;
    color: #87cec9;
    border-radius: 1rem;
    border: 1px solid #87cec9;
  }

  .phone_content_item_tag_btn_none {
    font-size: 0.8rem;
    padding: 0.1rem;
    margin-right: 0.1rem;
  }

  .phone_content_item_context {
    padding-top: 0.5rem;
    max-height: 6rem;
    overflow: hidden;
    color: #6a6a6a;
  }

  .phone_content_item_context img {
    width: 80%;
  }
  .phone_content_item_context p {
    text-align: left;
    text-indent: 0;
    margin-bottom: 0;
  }

  .phone_content_item_imgs {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .phone_content_item_imgs_item {
    width: 60%;
    margin-right: 0.5rem;
  }

  .see_more {
    color: #1890ff;
  }

  /* 论坛列表 */

  .bbslist {
    background: #f7f7f7;
  }

  .bbslist_search {
    background: #ffffff;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bbslist_search_item {
    width: 90%;
  }

  .bbslist_filter {
    background: #ffffff;
  }

  .bbs_list_content {
    padding: 1rem;
  }

  .phone_content_item_bottom {
    text-align: right;
  }

  .phone_bbs_index_top_detail {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .phone_bbs_index_top_detail_item {
    font-size: 1rem;
    font-weight: 700;
    width: 33%;
  }

  .phone_bbs_index_top_detail_item:nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .phone_bbs_detail_context {
    padding: 1rem;
    margin: 0 0.5rem;
    background: #ffffff;
    border-radius: 1rem;
    margin-top: -3rem;
  }

  .phone_bbs_detail_context_item {
    padding: 1rem 0;
  }

  .phone_bbs_detail_context_item img {
    width: 80%;
  }

  .phone_bbs_detail_context_comment_send {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .phone_bbs_detail_context_comment_input {
    width: 70%;
    border: 0.01rem solid #f6f6f6;
    border-radius: 1rem;
    padding: 0.2rem;
    background: #f6f6f6;
  }

  .phone_bbs_detail_context_comment_btn {
    background: #f6f6f6;
    padding: 0.1rem 0.6rem;
    border-radius: 0.7rem;
    box-shadow: 0px 1px 6px #dfe5e3;
  }

  .phone_bbs_detail_context_comment_item {
    padding-top: 2rem;
  }

  .phone_bbs_detail_context_comment_item_info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .phone_bbs_detail_context_comment_item_info_name {
    padding-left: 0.5rem;
  }

  .phone_bbs_detail_context_comment_item_action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1rem 0;
  }

  .phone_bbs_detail_context_comment_item_text img {
    height: 4rem;
    width: 4rem;
  }

  .phone_content_item_title_btn {
    margin-left: 1rem;
    background: #f6f6f6;
    padding: 0.1rem 0.6rem;
    border-radius: 0.7rem;
    box-shadow: 0px 1px 6px #dfe5e3;
  }

  .phone_replys {
    background: #f6f6f6;
    border-radius: 0.4rem;
  }

  .phone_reply {
    width: 100%;
    padding: 0.2rem;
    display: flex;
  }

  .phone_reply_name {
    padding-right: 10px;
    flex: 1 1 40%;
    width: 40%;
  }

  .phone_reply_content {
    flex: 1 1 100%;
  }

  .phone_reply img {
    height: 4rem;
    width: 4rem;
  }

  .phone_popup_contnet_item {
    padding: 10px 20px;
    padding-top: 10px;
    font-size: 20px;
    border-bottom: 1px solid #e1e1e1;
  }

  .phone_post_bbs {
    position: fixed;
    z-index: 998;
    right: 5%;
    bottom: 20%;
  }

  .phone_post_bbs_item {
    width: 60px;
    height: 60px;
    padding-top: 15%;
    margin-top: 10px;
    border-radius: 40px;
    text-align: center;
    background: #29f6f8;
  }

  .phone_post_bbs_item:nth-child(3) {
    width: 60px;
    padding-top: 15%;
    height: 60px;
    margin-top: 10px;
    border-radius: 40px;
    text-align: center;
    background: #f7ed4e;
  }

  .phone_post_bbs_item_title {
    font-size: 10px;
  }

  .maskData {
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-top: 25px;
  }

  .maskData_item {
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .maskData_item_icon {
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/phone1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .maskData_item_icon2 {
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/phone2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .maskData_item_icon3 {
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/phone3.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .maskData_item_icon4 {
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/phone4.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .maskData_item_icon5 {
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/phone5.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .maskData_item_icon6 {
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/phone6.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .maskData_item_icon7 {
    width: 3rem;
    height: 3rem;
    background: url(../../../assets/phone/phone7.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .maskData_item_left {
    width: 40%;
    text-align: -webkit-right;
  }

  .maskData_item_title {
    width: 60%;
    padding-left: 10px;
    font-size: 20px;
    color: #ffffff;
  }

  .new_bbs_top {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  }

  .new_bbs_top_right {
    padding: 5px 20px;
    background: #3fbf9e !important;
    border-radius: 20px !important;
    color: #ffffff !important;
  }

  .adm-list-item-content {
    border: none;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    border-color: #3fbf9e !important;
  }

  .ant-select {
    width: 100%;
  }
}
