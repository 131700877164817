.phone_popup_search {
    padding: 20px;
}


.phone_popup_search_title {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.phone_popup_search_title_left {
    font-size: 20px;
    font-size: 500;
}
.phone_popup_search_history {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.phone_popup_search_history_item {
    margin: 3px;
    border: 1px solid #e1e1e1;
    border-radius: 20px;
    padding:5px 15px;
}