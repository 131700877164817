.nav_content {
  width: 100%;
  color: #ffffff;
  background-color: #202329;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 2rem 0 3.6rem;
  height: 0.56rem;
  font-size: 0.14rem;
  position: fixed;
  top: 0;
  z-index: 199;
}

.nav_content_white {
  width: 100%;
  background-color: #ffffff;
  color: #2a2c2c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 2rem 0 3.6rem;
  height: 0.56rem;
  font-size: 0.14rem;
  position: fixed;
  top: 0;
  z-index: 199;
  margin-bottom: 0.56rem;
}

.nav_content_white .gotoregins a {
  color: #202329;
}
.nav_content_left {
  cursor: pointer;
  width: 30%;
}

.nav_content_right {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav_content_right_left {
  cursor: pointer;
  width: 30%;
}

.nav_content_right_content {
  width: 70%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-around;
}

.nav_content_right_content_item {
  width: 40%;
  height: 0.56rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-around;
}

.nav_content_right_content_item_active {
  width: 25%;
  text-align: center;
  line-height: 0.56rem;
  background: #3fd5d3;
  height: 0.56rem;
  color: #ffffff;
}

.gotoregins {
  font-size: 0.12rem;
  padding-left: 0.1rem;
}

.gotoregins a {
  color: #ffffff;
}

.gotoregins a:hover {
  color: #ffffff;
}

.nowlogin {
  font-size: 0.12rem;
  border: 1px solid #3fd5d3;
  height: 0.22rem;
  width: 0.5rem;
  text-align: center;
  line-height: 0.20rem;
  border-radius: 0.15rem;
}

.nowlogin a {
  color: #3fd5d3;
}

.nowlogin a:hover {
  color: #3fd5d3;
}

.customer_hover:hover{
  color: #3fd5d3;
}

/* 自适应css代码 */

@media (max-width: 768px),
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
}
