.mainmer_content {
  height: 100%;
  background: url("../../../assets/components/merbg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.middle_content {
  width: 100%;
  min-height: 4rem;
  padding-bottom: 0.2rem;
  background: url("../../../assets/contactus/wei.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.middle_content_bottom {
  width: 100%;
  min-height: 2rem;
  padding-bottom: 0.2rem;
  background: url("../../../assets/contactus/wei.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.why_choose {
  margin: 0 auto;
  width: 12rem;
  text-align: center;
}

.why_choose_title {
  padding-top: 0.2rem;
  color: #ffffff;
  font-size: 0.32rem;
  font-weight: 700;
}

.why_choose_title::after {
  margin: 0 auto;
  margin-top: 0.2rem;
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.04rem;
  background: #ffffff;
}

.why_choose_content {
  margin: 0 auto;
  width: 12rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.16rem;
  letter-spacing: 0.03rem;
  padding-top: 0.2rem;
}

.middle_tow_content {
  width: 12rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.middle_tow_content_item {
  margin-bottom: 0.4rem;
  margin-right: 0.1rem;
  width: 3.88rem;
  height: 3.94rem;
  padding: 0.73rem 0.48rem;
  background: #ffffff;
  box-shadow: 0px 4px 26px rgba(172, 204, 204, 0.25);
  border-radius: 0.16rem;
}

.middle_tow_content_item_img {
  width: 1rem;
  height: 0.9167rem;
  background: url("../../../assets/contactus/connect1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.middle_tow_content_item:nth-child(2) .middle_tow_content_item_img {
  width: 1rem;
  height: 0.9167rem;
  background: url("../../../assets/contactus/connect2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.middle_tow_content_item:nth-child(3) .middle_tow_content_item_img {
  width: 1rem;
  height: 0.9167rem;
  background: url("../../../assets/contactus/connect3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.middle_tow_content_item:nth-child(4) .middle_tow_content_item_img {
  width: 1rem;
  height: 0.9167rem;
  background: url("../../../assets/contactus/connect4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.middle_tow_content_item:nth-child(5) .middle_tow_content_item_img {
  width: 1rem;
  height: 0.9167rem;
  background: url("../../../assets/contactus/connect5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.middle_tow_content_item:nth-child(6) .middle_tow_content_item_img {
  width: 1rem;
  height: 0.9167rem;
  background: url("../../../assets/contactus/connect6.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.middle_tow_content_item_title {
  padding: 0.1rem 0;
  color: rgba(42, 44, 44, 0.85);
  font-size: 0.2rem;
}

.middle_tow_content_item_text {
  color: rgba(42, 44, 44, 0.65);
  font-size: 0.14rem;
}

.learn_more {
  width: 12rem;
  margin: 0 auto;
  text-align: center;
}

.learn_more_btn {
  margin: 0 auto;
  width: 1.85rem;
  height: 0.46rem;
  font-size: 0.2rem;
  line-height: 0.46rem;
  color: #18b2b0;
  text-align: center;
  border: 1px solid #18b2b0;
}

.mainmer_content {
  display: block;
}

@media (max-width: 1000px),
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .mainmer_content {
    display: none;
  }

  .merch_top {
    background: #18b2b0;
  }

  .middle_content {
    margin-top: 0;
  }

  .why_choose_content {
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
  }

  .middle_tow_content {
    width: 100%;
  }

  .middle_tow_content_item {
    width: 100%;
    height: 100%;
  }

  .middle_tow_content_item_title {
    font-size: 20px;
    font-weight: 700;
  }

  .middle_tow_content_item_text {
    font-size: 15px;
    font-weight: 500;
  }

  .why_choose_title {
    font-size: 20px;
    font-weight: 700;
  }

  .middle_content {
    padding-bottom: 20px;
  }
}
